<template>
  <div class="wish-detail">
    <!-- <van-nav-bar title="认领心愿" left-arrow @click-left="onClickLeft" /> -->
    <div class="wish-detail-body">
      <div class="img">
        <img :src="detailData.materialId" alt />
      </div>
      <h3>{{detailData.wishTitle}}</h3>
      <div class="post-info">
        <p>
          许愿人：{{detailData.wishPersonName}}
          <img
            src="./../../assets/img/contact.png"
            alt
            v-if="detailData.wishPersonMobile&&detailData.wishPersonName!==realName && detailData.givePersonnelName==realName"
            @click.stop="toCall(detailData.wishPersonMobile)"
          />
          <!-- 别人认领的不显示号码&&自己认领的显示 -->
        </p>
        <p>发布时间：{{detailData.startTime}}</p>
        <p>截至时间：{{detailData.endTime}}</p>
      </div>
      <div class="wish-content">
        <h5>心愿内容</h5>
        <div class="content">{{detailData.description}}</div>
      </div>
      <div class="checked-info" v-if="detailData.wishStatus==3 || detailData.wishStatus==4">
        <h5>认领信息</h5>
        <p>
          认领人：{{detailData.givePersonnelName}}
          <img
            src="./../../assets/img/contact.png"
            alt
            v-if="detailData.givePersonnelMobile&&detailData.wishPersonName==realName"
            @click.stop="toCallCatch(detailData.givePersonnelMobile)"
          />
        </p>
        <p>领办时间：{{detailData.giveAt}}</p>
        <p>备注：{{detailData.feedback}}</p>
      </div>
      <div class="checked-info" v-if="detailData.wishStatus==5">
        <h5>审核信息</h5>
        <p>审核状态：{{detailData.auditStatus==1?'通过':'驳回'}}</p>
        <p>审核人：{{detailData.auditPersonnelName}}</p>
        <p>审核时间：{{detailData.auditAt}}</p>
      </div>
      <!-- <button
        class="finish"
        v-if="detailData.wishStatus==3&&detailData.givePersonnelName==realName"
        @click="finishWish"
      >完成</button>-->
    </div>
    <div class="tab-bottom">
      <button
        class="getIt"
        @click="togetWish(detailData.id)"
        v-if="!loading && detailData.wishPersonName!==realName && detailData.wishStatus==2"
      >我要认领</button>
    </div>
    <div class="tab-bottom" v-if="detailData.wishStatus==1">
      <button class="reject" @click="checkStatus(2)">拒绝</button>
      <button class="pass" @click="checkStatus(1)">通过</button>
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click.stop="show=false">
        <div class="block">
          <h3>许愿人的电话</h3>
          <p class="call">{{detailData.wishPersonMobile}}</p>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showCatch">
      <div class="wrapper" @click.stop="showCatch=false">
        <div class="block">
          <h3>领取人的电话</h3>
          <p class="call">{{detailData.givePersonnelMobile}}</p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Notify, Toast } from 'vant';
import { detail, requestFinish, requestChecked } from '@/api/tinyWish';
export default {
  data() {
    return {
      detailData: {},
      realName: '',
      show: false,
      showCatch: false,
      phone: '',
      loading: false
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'tinyWish' });
    },
    //完成
    finishWish() {
      requestFinish({
        id: this.$route.params.id
      }).then(res => {
        if (res.success) {
          Notify({ type: 'success', message: '已完成该心愿' });
          setTimeout(() => {
            this.$router.push({ name: 'tinyWish' });
          }, 500);
        }
      });
    },
    //打开与他联系框
    // openCallBox(phone) {
    //   this.phone = phone;
    // },
    //与他联系
    toCall(phone) {
      this.show = true;
      // this.phone = phone;
      // dd.biz.telephone.showCallMenu({
      //   phoneNumber: this.phone, // 期望拨打的电话号码
      //   code: '+86', // 国家代号，中国是+86
      //   showDingCall: true, // 是否显示钉钉电话
      //   onSuccess: function() {
      //     console.log('成功');
      //   },
      //   onFail: function() {
      //     console.log('失败');
      //   }
      // });
    },
    toCallCatch(phone) {
      this.showCatch = true;
    },
    //我要认领
    togetWish(id) {
      this.$router.push({ name: 'getWish', params: { id } });
    },
    //拒绝或通过
    checkStatus(status) {
      requestChecked({
        id: this.$route.params.id,
        auditStatus: status
      })
        .then(res => {
          if (res.success) {
            status == 1 && Toast.success('已通过该心愿');
            status == 2 && Toast.success('已拒绝该心愿');
            setTimeout(() => {
              this.$router.go(-1);
            }, 600);
            return false;
          }
          if (res.code == 10041) {
            Notify({ type: 'warning', message: res.msg });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.realName = JSON.parse(localStorage.getItem('user')).realName;
  },
  mounted() {
    detail({
      id: this.$route.params.id
    })
      .then(res => {
        console.log(res);
        this.detailData = res.data;
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
        this.loading = false;
      });
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.wish-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .wish-detail-body {
    background: #f3f3f3;
    flex: 1;
    .img {
      // width: 750 * $px;
      // height: 400 * $px;
      background: url('./../../assets/img/list.png') center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      background: #fff;
      font-size: 32 * $px;
      font-family: PingFang SC;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      height: 48 * $px;
      line-height: 48 * $px;
      padding: 24 * $px 0 24 * $px 24 * $px;
      border-bottom: 2 * $px solid #f3f3f3;
    }
    .post-info {
      background: #fff;
      padding: 24 * $px 0 24 * $px 24 * $px;
      font-size: 24 * $px;
      color: rgba(85, 85, 85, 1);
      p:nth-of-type(2) {
        margin: 14 * $px 0;
      }
      img {
        width: 132 * $px;
        height: 30 * $px;
      }
    }
    .wish-content {
      margin-top: 24 * $px;
      background: #fff;
      padding: 27 * $px 24 * $px;
      h5 {
        font-size: 32 * $px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 24 * $px;
      }
      .content {
        font-size: 28 * $px;
        color: rgba(34, 34, 34, 1);
      }
    }
    .checked-info {
      margin-top: 24 * $px;
      background: #fff;
      padding: 27 * $px 24 * $px;
      h5 {
        font-size: 32 * $px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 24 * $px;
      }
      p {
        font-size: 28 * $px;
        color: rgba(34, 34, 34, 1);
        &:nth-of-type(2) {
          margin: 10 * $px 0;
        }
        img {
          width: 132 * $px;
          height: 30 * $px;
        }
      }
    }
    .finish {
      width: 690 * $px;
      height: 88 * $px;
      background: rgba(255, 25, 47, 1);
      border: 0;
      font-size: 32 * $px;
      color: rgba(255, 255, 255, 1);
      border-radius: 44 * $px;
      margin: 40 * $px 30 * $px;
    }
  }
  .tab-bottom {
    height: 100 * $px;
    width: 100%;
    background: #f3f3f3;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 32 * $px;

    button {
      color: rgba(255, 255, 255, 1);
      border: 0;
      width: 690 * $px;
      height: 88 * $px;
      background: rgba(255, 25, 47, 1);
      border-radius: 44 * $px;
      &.reject {
        width: 190 * $px;
        height: 88 * $px;
        background: rgba(255, 255, 255, 1);
        border-radius: 44 * $px;
        color: #555;
      }
      &.pass {
        width: 470 * $px;
        height: 88 * $px;
        background: rgba(255, 25, 47, 1);
        border-radius: 44 * $px;
        color: #fff;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 600 * $px;
      height: 300 * $px;
      background-color: #fff;
      border-radius: 8 * $px;
      padding: 30 * $px;
      box-sizing: border-box;
      h3 {
        font-size: 32 * $px;
        padding-left: 20 * $px;
      }
      p {
        font-size: 36 * $px;
        &.call {
          margin: 40 * $px;
          margin-left: 20 * $px;
          text-align: center;
        }
        &.is {
          margin: 10 * $px 0 0 280 * $px;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}
</style>